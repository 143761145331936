<template>
  <div class="tw-max-w-9xl tw-mx-auto sm:tw-px-8 lg:tw-px-16 tw-px-3">
    <div class="tw-pt-4 sm:tw-pt-24 tw-text-center">
      <ml-alert v-if="error" :title="error.message" :msg="error.errors"/>
<!--      <v-alert-->
<!--        v-if="error"-->
<!--        color="orange"-->
<!--        outlined-->
<!--        shaped-->
<!--        type="info"-->
<!--      >-->
<!--      {{error.message}}-->
<!--      {{error.errors}}-->

<!--      </v-alert>-->

      <h1 class="tw-block tw-text-black tw-text-xl tw-leading-relaxed tw-font-semibold tw-mb-2">
        {{ $t('auth.register') }}
      </h1>
      <p class="tw-text-lg tw-text-gray-n3 tw-mb-0">
        Welcome to <span class="tw-font-serif tw-text-red">Mango & Lola</span>
      </p>
    </div>

    <ml-alert v-if="mustVerifyEmail" :title="$t('auth.verify_account')" :msg="$t('auth.verify_account_info1') + this.data.email + '.' + $t('auth.verify_account_info2')"/>

    <form v-else class="sm:tw-w-2/5 tw-px-4 tw-mx-auto tw-text-left tw-mt-6 tw-pb-12" novalidate="true" @submit.prevent="onSubmit" method="POST">
      <ml-error-msg :msg="errors['error']" />
      <label class="tw-mb-0 tw-block tw-text-sm tw-font-semibold tw-text-gray-n3 tw-leading-relaxed">
        I am a <span class="tw-text-red">*</span>
      </label>
      <div class="tw-mt-2 tw-grid tw-grid-cols-1 tw-grid-cols-2 tw-gap-x-4 tw-mb-6">
        <label :class="form.type === '0' ? 'tw-border-transparent tw-bg-gray-n0' : 'tw-border-gray-n1 tw-bg-white'" class="tw-mb-0 tw-relative tw-border tw-rounded tw-p-4 tw-flex tw-cursor-pointer focus:tw-outline-none">
          <input v-model="form.type" type="radio" name="project-type" value="0" class="tw-sr-only" aria-labelledby="project-type-0-label" aria-describedby="project-type-0-description-0">
          <div class="tw-flex-1 tw-flex">
            <div class="tw-flex tw-flex-col">
              <span id="project-type-0-label" class="tw-block tw-text-xs tw-font-semibold tw-text-gray-900">
                {{$t('auth.register_for_user')}}
              </span>
            </div>
          </div>

          <svg v-if="form.type === '0'" width="16" height="16" viewBox="-1 -1 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8Z" fill="#E1E1E1"/>
            <circle cx="8" cy="8" r="6" fill="#2D2D2D"/>
            <path d="M8 15C4.13401 15 1 11.866 1 8H-1C-1 12.9706 3.02944 17 8 17V15ZM15 8C15 11.866 11.866 15 8 15V17C12.9706 17 17 12.9706 17 8H15ZM8 1C11.866 1 15 4.13401 15 8H17C17 3.02944 12.9706 -1 8 -1V1ZM8 -1C3.02944 -1 -1 3.02944 -1 8H1C1 4.13401 4.13401 1 8 1V-1Z" fill="#2D2D2D"/>
          </svg>
          <div v-else class="tw-h-4 tw-w-4 tw-border-2 tw-border-gray-n1 tw-rounded-full">
          </div>

          <!--
            Active: "border", Not Active: "border-2"
            Checked: "border-indigo-500", Not Checked: "border-transparent"
          -->
          <div :class="form.type === '0' ? 'tw-border-gray-n4' : 'tw-border-transparent'" class="tw-absolute tw--inset-px tw-rounded tw-border-2 tw-pointer-events-none" aria-hidden="true"></div>
        </label>
        <label :class="form.type === '1' ? 'tw-border-transparent tw-bg-gray-n0' : 'tw-border-gray-n1 tw-bg-white'" class="tw-mb-0 tw-relative tw-border tw-rounded tw-p-4 tw-flex tw-cursor-pointer focus:tw-outline-none">
          <input v-model="form.type" type="radio" name="project-type" value="1" class="tw-sr-only" aria-labelledby="project-type-1-label" aria-describedby="project-type-1-description-0 project-type-1-description-1">
          <div class="tw-flex-1 tw-flex">
            <div class="tw-flex tw-flex-col tw-text-black">
              <span id="project-type-1-label" class="tw-block tw-text-xs tw-font-semibold">
                {{$t('auth.register_for_vendor')}}
              </span>
            </div>
          </div>

          <svg v-if="form.type === '1'" width="16" height="16" viewBox="-1 -1 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8Z" fill="#E1E1E1"/>
            <circle cx="8" cy="8" r="6" fill="#2D2D2D"/>
            <path d="M8 15C4.13401 15 1 11.866 1 8H-1C-1 12.9706 3.02944 17 8 17V15ZM15 8C15 11.866 11.866 15 8 15V17C12.9706 17 17 12.9706 17 8H15ZM8 1C11.866 1 15 4.13401 15 8H17C17 3.02944 12.9706 -1 8 -1V1ZM8 -1C3.02944 -1 -1 3.02944 -1 8H1C1 4.13401 4.13401 1 8 1V-1Z" fill="#2D2D2D"/>
          </svg>
          <div v-else class="tw-h-4 tw-w-4 tw-border-2 tw-border-gray-n1 tw-rounded-full">
          </div>
          <!--
            Active: "border", Not Active: "border-2"
            Checked: "border-indigo-500", Not Checked: "border-transparent"
          -->
          <div :class="form.type === '1' ? 'tw-border-gray-n4' : 'tw-border-transparent'" class="tw-absolute tw--inset-px tw-rounded tw-border-2 tw-pointer-events-none" aria-hidden="true"></div>
        </label>
      </div>

      <div>
        <label for="name" class="tw-mb-0 tw-block tw-text-sm tw-font-semibold tw-text-gray-n3 tw-leading-relaxed">
          {{ $t('auth.name') }} <span class="tw-text-red">*</span>
        </label>
        <div class="tw-mt-2">
          <input v-model="form.name" autofocus id="name" name="name" type="text" autocomplete="name" required class="tw-appearance-none tw-block tw-w-full tw-px-4 tw-py-3 tw-border tw-border-solid tw-border-gray-n1 tw-rounded-xl tw-placeholder-gray-n1 focus:tw-outline-none focus:tw-ring-black focus:border-black sm:tw-text-sm">
          <ml-error-msg :msg="errors['name']" />
        </div>
      </div>

      <div class="tw-mt-6">
        <label for="email" class="tw-mb-0 tw-block tw-text-sm tw-font-semibold tw-text-gray-n3 tw-leading-relaxed">
          Email Address <span class="tw-text-red">*</span>
        </label>
        <div class="tw-mt-2">
          <input v-model="form.email" autofocus id="email" name="email" type="email" autocomplete="email" required class="tw-appearance-none tw-block tw-w-full tw-px-4 tw-py-3 tw-border tw-border-solid tw-border-gray-n1 tw-rounded-xl tw-placeholder-gray-n1 focus:tw-outline-none focus:tw-ring-black focus:border-black sm:tw-text-sm">
          <ml-error-msg :msg="errors['email']" />
        </div>
      </div>

      <div class="tw-mt-6">
        <label for="password" class="tw-mb-0 tw-block tw-text-sm tw-font-semibold tw-text-gray-n3 tw-leading-relaxed">
          Password <span class="tw-text-red">*</span>
        </label>
        <div class="tw-mt-2 tw-relative tw-rounded-xl tw-border tw-border-gray-n1">
          <input v-model="form.password" id="password" name="password" :type="showPasswordValue ? 'text' : 'password'" autocomplete="current-password" required class="tw-appearance-none tw-bg-transparent tw-block tw-w-full tw-px-4 tw-py-3 tw-border-none tw-placeholder-gray-n1 focus:tw-outline-none focus:tw-ring-black focus:border-black sm:tw-text-sm">
          <a class="tw-absolute tw-inset-y-0 tw-right-0 tw-pr-3 tw-flex tw-items-center" @click.prevent.stop="togglePassword" @mouseleave="hidePassword">
            <eye-icon class="tw-w-4 tw-h-4 tw-text-gray-n3" />
          </a>
        </div>
        <ml-error-msg :msg="errors['password']" />
      </div>

      <div class="tw-mt-6">
        <label for="confirm_password" class="tw-mb-0 tw-block tw-text-sm tw-font-semibold tw-text-gray-n3 tw-leading-relaxed">
          {{ $t('auth.confirm_password') }} <span class="tw-text-red">*</span>
        </label>
        <div class="tw-mt-2 tw-relative tw-rounded-xl tw-border tw-border-gray-n1">
          <input v-model="form.password_confirmation" id="confirm_password" name="confirm_password" :type="showPasswordValue ? 'text' : 'password'" autocomplete="confirm_password" required class="tw-appearance-none tw-bg-transparent tw-block tw-w-full tw-px-4 tw-py-3 tw-border-none tw-placeholder-gray-n1 focus:tw-outline-none focus:tw-ring-black focus:border-black sm:tw-text-sm">
          <a class="tw-absolute tw-inset-y-0 tw-right-0 tw-pr-3 tw-flex tw-items-center" @click.prevent.stop="togglePassword" @mouseleave="hidePassword">
            <eye-icon class="tw-w-4 tw-h-4 tw-text-gray-n3" />
          </a>
        </div>
        <ml-error-msg :msg="errors['password_confirmation']" />
      </div>

      <div class="tw-mt-6" v-if="form.type === '0'">
        <label for="name" class="tw-mb-0 tw-block tw-text-sm tw-font-semibold tw-text-gray-n3 tw-leading-relaxed">
          {{ $t('auth.phone') }} <span class="tw-text-red">*</span>
        </label>
        <div class="tw-mt-2">
          <input v-model="form.phone" autofocus id="phone" name="phone" type="text" autocomplete="phone"  class="tw-appearance-none tw-block tw-w-full tw-px-4 tw-py-3 tw-border tw-border-solid tw-border-gray-n1 tw-rounded-xl tw-placeholder-gray-n1 focus:tw-outline-none focus:tw-ring-black focus:border-black sm:tw-text-sm">
          <ml-error-msg :msg="errors['phone']" />
        </div>
      </div>

      <div class="tw-mt-6" v-if="form.type === '0'">
        <label for="invite_friend" class="tw-mb-0 tw-block tw-text-sm tw-font-semibold tw-text-gray-n3 tw-leading-relaxed">
          {{ $t('auth.invite_friend') }}
        </label>
        <div class="tw-mt-2">
          <input v-model="form.partner" autofocus id="invite_friend" name="invite_friend" type="email" autocomplete="invite_friend" class="tw-appearance-none tw-block tw-w-full tw-px-4 tw-py-3 tw-border tw-border-solid tw-border-gray-n1 tw-rounded-xl tw-placeholder-gray-n1 focus:tw-outline-none focus:tw-ring-black focus:border-black sm:tw-text-sm">
        </div>
      </div>
      <div class="tw-mt-6 tw-flex tw-items-left tw-space-x-3">

        <input v-model="form.terms_and_conditions" id="terms_and_conditions" type="checkbox" value="1"
        class="w-4 h-4 tw-text-red tw-bg-gray-n tw-ring-1 tw-ring-black rounded tw-border-black focus:tw-ring-red
        dark:focus:tw-ring-red-100 dark:tw-ring-offset-gray-n1 focus:tw-ring-2 dark:tw-bg-gray-n1 dark:tw-border-gray-n2">

        <label for="terms_and_conditions" class="tw-text-xs tw-leading-relaxed tw-text-black">
          By submitting this form, I agree to be bound by the <router-link target="_blank" class="tw-text-red" :to="{ name: 'terms.and.conditions'}">{{ $t('footer.terms_and_conditions') }}</router-link>
          and <router-link target="_blank" class="tw-text-red" :to="{ name: 'privacy.policy'}">{{ $t('footer.privacy_policy') }}</router-link>.
        </label>

      </div>
      <ml-error-msg :msg="errors['terms_and_conditions']" />
      <spinner v-if="loading"></spinner>
      <button type="submit" :disabled="loading" class="tw-mt-6 tw-flex tw-justify-center tw-w-full tw-px-6 tw-py-4 tw-border tw-border-transparent tw-text-base tw-font-medium tw-rounded-xl tw-shadow-sm tw-text-white tw-bg-gray-n4 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-offset-2 focus:tw-ring-gray-n4">
        {{ $t('auth.register') }}
      </button>
    </form>
 </div>
</template>

<script>
import Form from 'vform'
import { extend } from 'vee-validate';
import i18n from '@/i18n'
//import MlLink from "@/components/layout/MlLink";
import { EyeIcon } from 'vue-feather-icons'
import MlErrorMsg from "@/components/layout/form/MlErrorMsg";
import MlAlert from "@/components/layout/MlAlert";

extend('password', {
  params: ['target'],
  validate(value, { target }) {
    return value === target;
  },
  message: i18n.t('auth.password_mismatch')
});


export default {
  components: {
    //MlLink,
    EyeIcon,
    MlErrorMsg,
    MlAlert
  },
  middleware: 'guest',

  metaInfo () {
    return { title: i18n.t('auth.register')  }
  },



data: () => ({
    appName: process.env.VUE_APP_TITLE ? process.env.VUE_APP_TITLE : '...',
    showPassword: false,
    valid: true,
    loading: false,
    lazy: true,
    showPasswordValue: false,
    validPassword: "",
    form: new Form({
      name: '',
      email: '',
      password: '',
      password_confirmation: '',
      partner: '',
      type: '1',
      phone: ''
    }),
    mustVerifyEmail: false,
    errors: [],
    error: null,
    data: [],
    rules: {
        required: value => !!value || i18n.t('auth.required'),
        min: v => v.length >= 8 || i18n.t('auth.required_min'),
        passwordRules: v => !!v || i18n.t('auth.required_password'),
        confirmPasswordRules: v => !!v || i18n.t('auth.required_password_confirmation')
     },
    emailRules: [
          v => !!v || i18n.t('auth.email_required'),
          v => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || i18n.t('auth.email_valid'),
    ],

  }),
  computed:{

    confirmPasswordMatchRules() {
      return v => (v === this.form.password) || i18n.t('auth.password_mismatch')
    },
    partnerEmailRules() {
      if (this.form.type == 0){
        if(this.form.partner.length > 0){
          return v => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || i18n.t('auth.email_valid')
        }else{
          return true;
        }

      }else{
        return true;
      }
    },
  },
  mounted () {
        var q = this.$route.query.type

        if(q == "1"){
          this.form.type = "0";
        }else if(q == "2"){
          this.form.type = "1";
        }

      if (this.$route.query.name){
        this.form.name = this.$route.query.name;
      }
      if (this.$route.query.email){
        this.form.email = this.$route.query.email;
      }


    },

  methods: {
    togglePassword() {
      this.showPasswordValue = !this.showPasswordValue;
    },
    hidePassword() {
      this.showPasswordValue = false;
    },
    checkPassword(invalid) {
    // correct: false
    if (true == invalid) {
      this.validPassword = false;
    } else {
      this.validPassword = true;
    }
   },
   checkForm: function () {
      this.errors = [];
      let label = '';
      this.loading = false;


      if (!this.form.terms_and_conditions) {
        label = "Terms and Conditions Required";
        this.errors["terms_and_conditions"] = label;
        this.errors.push(label);
      }
      if (!this.form.name) {
        label = "Name Required";
        this.errors["name"] = label;
        this.errors.push(label);
      }
     if (!this.form.phone && this.form.type === '0') {
       label = "Phone Number Required";
       this.errors["phone"] = label;
       this.errors.push(label);
     }
      if (!this.form.email) {
        label = "Email Required";
        this.errors.push(label);
        this.errors["email"] = label;
      } else if (!this.validEmail(this.form.email)) {
        label = "Valid Email Required";
        this.errors.push(label);
        this.errors["email"] = label;
      }

      if (!this.form.password) {
        label = i18n.t('auth.required_password');
        this.errors["password"] = label;
        this.errors.push(label);
      }else{
        if(this.form.password.length < 8){
          label = i18n.t('auth.password_min');
          this.errors["password"] = label;
          this.errors.push(label);
        }
        if (this.form.password !== this.form.password_confirmation) {
          label = i18n.t('auth.password_mismatch');
          this.errors["password_confirmation"] = label;
          this.errors.push(label);
        }
      }

      if (!this.errors.length) {
        this.valid = true;
        return true;
      }
      this.valid = false;
      return false;
    },
    validEmail: function (email) {
      var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },
    onSubmit () {
      if(this.checkForm()) this.register();
    },
    async register () {

      if (!this.valid) return;
      /*
      await this.$refs.form.validate();
      if (!this.valid) return;
      */
      // Register the user.
      //const {data} = await this.form.post('/api/register')
      this.error = null;
      this.loading = true;

      try{


          await this.$http.get('sanctum/csrf-cookie');
          //await this.$http.post('api/register',this.form)
          await this.form.post('api/register')
            .then(({data})  => {
              this.data = data
            })
            .catch((error) => {
              this.loading = false;
              this.error = error.data;
              this.errors["error"] = "An error has occurred";
              // Error
              if (error.response) {
                  // The request was made and the server responded with a status code
                  // that falls out of the range of 2xx
                  // console.log(error.response.data);
                  // console.log(error.response.status);
                  // console.log(error.response.headers);
                  console.log('Error response', error.response);
              } else if (error.request) {
                  // The request was made but no response was received
                  // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                  // http.ClientRequest in node.js
                  console.log('Error request.response', error.request.response);

              } else {
                  // Something happened in setting up the request that triggered an Error
                  console.log('Error message', error.message);
              }
              //console.log('Error', error);
              return;
          });

            // Must verify email fist.
            if (this.data.status) {
              this.mustVerifyEmail = true;
              this.$toast.info(this.data.status, "Success");
            }else if(!this.error || this.data.registered) {
              // Log in the user.
            const { data: { token } } = await this.form.post('/api/login')

              // Save the token.
              await this.$store.dispatch('auth/saveToken', { token })
              await this.$store.dispatch('auth/fetchUser')

              // Update the user.
              //await this.$store.dispatch('auth/updateUser', { user: data })
              this.$toast.success(this.data.registered, "Success");
              // Redirect home.
              if(this.form.type == 1){
                this.$router.push({ name: 'vendor.onboarding' })
              }else{
                this.$router.push({ name: 'welcome' })
              }


            }
            this.loading = false;

      } catch (error) {
        this.error = error.data;
        this.errors["error"] = "An error has occurred";
      }
    }
  }
}
</script>
